import moment from "moment";
import ApiService from "../services/ApiService";
interface UnusedStock {
  id: number;
  qty: string;
  remark: string;
  type: string;
  design: string;
  material: string;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const UnusedStocks: Array<UnusedStock> = [];

const deleteUnusedStock = function (item: UnusedStock) {
  return ApiService.delete(`unused-stock/${item.id}/`);
};

const addUnusedStock = function (item, file, id) {
  const formData = new FormData();
  delete item.logo;
  for (const key in item) {
    formData.append(key, item[key]);
  }
  if (file && file[0]) {
    formData.append("logo", file[0]);
  }
  if (id) {
    return ApiService.put(`unused-stock/${id}/`, formData);
  } else {
    return ApiService.post("unused-stock/", formData);
  }
};

const exportUnusedStockData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/unused-stock/?unused-stock_id=" + ids,
      "unused-stock-data"
    ).then((data) => res(data));
  });
};
const exportPDFUnusedStocks = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/unused-stock/?unused-stock_id=" + ids).then((data) =>
      res(data)
    );
  });
};
const unusedStockObjClone = function () {
  return {
    id: 0,
    qty: "",
    remark: "",
    type: "",
    design: "",
    material: "",
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: UnusedStock = unusedStockObjClone();
  newItem.id = item.id;
  newItem.qty = item.qty;
  newItem.remark = item.remark;
  newItem.type = item.type;
  newItem.design = item.design;
  newItem.material = item.material;
  newItem.created_by = item.created_by;
  return newItem;
};
const getUnusedStockById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`unused-stock/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getUnusedStocks = function (
  pageNumber = 1,
  searchData,
  status = "",
  user = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      created_by: user,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`unused-stock/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfaceUnusedStock = jsonToInterface;
const getEmptyObjOfUnusedStock = unusedStockObjClone;
export {
  UnusedStock,
  getUnusedStocks,
  deleteUnusedStock,
  addUnusedStock,
  getUnusedStockById,
  jsonToInterfaceUnusedStock,
  unusedStockObjClone,
  exportUnusedStockData,
  exportPDFUnusedStocks,
  getEmptyObjOfUnusedStock
};

export default UnusedStocks;
